import { useEffect } from 'react';
import useSchoolStore from '../stores/schoolStore';

export const useSchoolData = (selectedSchool) => {
  const store = useSchoolStore();
  
  // Initial schools fetch
  useEffect(() => {
    if (store.schools.length === 0) {
      store.fetchSchools();
    }
  }, [store]);

  // Handle school selection
  useEffect(() => {
    if (selectedSchool?.ncessch) {
      store.selectSchool(selectedSchool);
    } else {
      store.clearSchoolData();
    }
  }, [selectedSchool?.ncessch, selectedSchool, store]);

  return {
    schools: store.schools,
    schoolsLoading: store.loading.schools,
    esriData: store.esriData,
    nearbySchools: store.nearbySchools,
    enrollmentProjections: store.enrollmentProjections,
    schoolsEnrollmentData: store.schoolsEnrollmentData,
    error: store.error,
    isLoading: store.isLoading()
  };
};

export default useSchoolData;