// components/GeographicExplorer.js
import React, { useState, useEffect, useMemo, Suspense } from 'react';
import { 
  Skeleton,
} from '@mui/material';
import { 
  GroupsOutlined,
  CompareArrowsOutlined,
  PeopleOutlined,
  DonutSmallOutlined,
  AutoGraphOutlined,
  DescriptionOutlined,
  Explore
} from '@mui/icons-material';

// Shared Components
import PageLayout from '../shared_components/pageLayout';
import LoadingErrorWrapper from '../shared_components/loadingErrorWrapper';
import AccordionSection from '../shared_components/accordionSection';
import AccordionContainer from '../shared_components/accordionContainer';
import StickyFilters from '../shared_components/stickyFilters';
import InitialSchoolSelector from '../shared_components/initialSchoolSelector';
import NowWhatSection from '../shared_components/templates/nowWhatTemplate';

// Custom Hook
import { useAccordionState } from '../../hooks/useAccordionState';

// Utils
import { filterSchools, getAvailableSchoolTypes } from '../../utils/schoolUtils';
import { formatSchoolName } from '../../utils/nameFormatter';

// Stores
import useSchoolStore from '../../stores/schoolStore';

// Components
import SchoolPopup from './SchoolPopup';

// Lazy load components
const CommunityHeadlines = React.lazy(() => import('./CommunityHeadlines'));
const EnrollmentHeadlines = React.lazy(() => import('./EnrollmentHeadlines'));
const ComparisonHeadlines = React.lazy(() => import('./ComparisonHeadlines'));


function GeographicExplorer() {
  // State declarations
  const [governanceFilter, setGovernanceFilter] = useState('All');
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedDriveTime, setSelectedDriveTime] = useState(10);
  const [schoolTypeFilter, setSchoolTypeFilter] = useState('All');
  const [availableSchoolTypes, setAvailableSchoolTypes] = useState(['All']);

  // Use accordion state hook
  const {
    expandedSections,
    handleExpandedChange,
    handleExpandAll,
    handleCollapseAll,
  } = useAccordionState({
    communityheadlines: false,
    enrollmentHeadlines: false,
    comparisonHeadlines: false,
    nowWhat: false,
  });

  // Use the school store hook
  const {
    schools,
    selectedSchool,
    esriData,
    nearbySchools,
    schoolsEnrollmentData,
    error,
    schoolsLoading,
    contentLoading
  } = useSchoolStore();

  // Actions for the Now What section
  const actions = [
    {
      icon: DonutSmallOutlined,
      title: 'Competitive Explorer',
      description: 'Analyze market share trends and projections for schools in your commuity',
      path: '/marketshare-analyzer'
    },
    {
      icon: AutoGraphOutlined,
      title: 'Projections Explorer',
      description: 'Explore historical and projected enrollment data for the next five years',
      path: '/projections-explorer'
    },
    {
      icon: DescriptionOutlined,
      title: 'Data Reports',
      description: 'Download detailed reports, including Esri demographic data for selected schools',
      path: '/data-reports'
    }
  ];

  useEffect(() => {
    console.log('Raw schoolsEnrollmentData:', {
      count: schoolsEnrollmentData?.length,
      firstSchool: schoolsEnrollmentData?.[0],
      hasRaceData: !!schoolsEnrollmentData?.[0]?.race_data,
      hasEnrollmentGrades: !!schoolsEnrollmentData?.[0]?.enrollment_by_grade
    });
  }, [schoolsEnrollmentData]);
  
  // Update available school types
  useEffect(() => {
    if (schoolsEnrollmentData.length > 0) {
      const types = getAvailableSchoolTypes(schoolsEnrollmentData);
      setAvailableSchoolTypes(types);
    }
  }, [schoolsEnrollmentData]);

  // Filter schools based on criteria
  const filteredSchools = useMemo(() => {
    if (!selectedSchool || !nearbySchools) {
      console.log('Missing required data for filtering');
      return [];
    }
    
    console.log('Filtering schools:', {
      selectedSchool,
      nearbySchoolsCount: nearbySchools[selectedDriveTime]?.length,
      enrollmentDataCount: schoolsEnrollmentData?.length,
      selectedGrades,
      governanceFilter
    });
    
    // Get schools for the selected drive time from current schools
    const schoolsForSelectedDriveTime = nearbySchools?.current?.[selectedDriveTime] || [];
    
    // Find enrollment data for selected school
    const selectedSchoolEnrollment = schoolsEnrollmentData.find(
      d => d.ncessch === selectedSchool.ncessch
    );
    
    // Create array with selected school and nearby schools
    const allSchools = [
      { 
        ...selectedSchool, 
        ...selectedSchoolEnrollment,
        drive_time: 0 
      }, 
      ...schoolsForSelectedDriveTime.map(school => {
        const enrollmentData = schoolsEnrollmentData.find(
          d => d.ncessch === school.ncessch
        );
        return {
          ...school,
          ...enrollmentData,
          drive_time: selectedDriveTime
        };
      })
    ];
  
    console.log('Combined schools data:', {
      totalSchools: allSchools.length,
      selectedSchool: allSchools[0],
      nearbySchools: allSchools.slice(1),
    });
  
    // Only filter if we have schools and enrollment data
    if (allSchools.length === 0) return [];
  
    const filtered = filterSchools(
      allSchools,
      {
        selectedGrades,
        governanceFilter,
        schoolTypeFilter
      },
      schoolsEnrollmentData
    );
  
    console.log('Filtered schools:', {
      before: allSchools.length,
      after: filtered.length
    });
  
    return filtered;
  }, [
    selectedSchool,
    nearbySchools,
    selectedDriveTime,
    selectedGrades,
    governanceFilter,
    schoolTypeFilter,
    schoolsEnrollmentData
  ]);

  const handleSchoolChange = (event) => {
    const schoolId = event.target.value;
    const school = schools.find(s => s.ncessch === schoolId);
    if (school) {
      useSchoolStore.getState().selectSchool({
        ...school,
        name: formatSchoolName(school.name)
      });
    }
  };

  const handleGradeChange = (event) => {
    const value = event.target.value;
    setSelectedGrades(typeof value === 'string' ? value.split(',') : value);
  };

  const handleGovernanceChange = (event) => {
    setGovernanceFilter(event.target.value);
  };

  const handleDriveTimeChange = (event) => {
    setSelectedDriveTime(Number(event.target.value));
  };

  const handleSchoolTypeChange = (event) => {
    setSchoolTypeFilter(event.target.value);
  };

  return (
    <PageLayout
      title="Geographic Explorer"
      description={selectedSchool
        ? "Refine your analysis using the Governance, Grades, and Drive Time filters. Click on any headline card to view more detailed information and insights about the selected metrics."
        : "Use this tool to explore the geographic context of schools in your area. Start by selecting a school."
      }
    >
      <LoadingErrorWrapper
          loading={schoolsLoading}
          error={error}
          initialLoading={true}
        >
          {!selectedSchool ? (
            <InitialSchoolSelector
              schools={schools}
              selectedSchool={selectedSchool}
              onSchoolChange={handleSchoolChange}
            />
          ) : (
            <LoadingErrorWrapper
              loading={contentLoading}
              error={error}
              initialLoading={false}
            >
            <StickyFilters
              schools={schools}
              selectedSchool={selectedSchool}
              governanceFilter={governanceFilter}
              selectedGrades={selectedGrades}
              selectedDriveTime={selectedDriveTime}
              schoolTypeFilter={schoolTypeFilter}
              availableSchoolTypes={availableSchoolTypes}
              onSchoolChange={handleSchoolChange}
              onGovernanceChange={handleGovernanceChange}
              onGradeChange={handleGradeChange}
              onDriveTimeChange={handleDriveTimeChange}
              onSchoolTypeChange={handleSchoolTypeChange}
              expandedSections={expandedSections}
              onExpandAll={handleExpandAll}
              onCollapseAll={handleCollapseAll}
              showSchoolType={true}
            />

            <AccordionContainer 
              expandedSections={expandedSections}
              onSectionChange={handleExpandedChange}
            >

              <AccordionSection
                id="communityheadlines"
                title="Your Community"
                summary={`Demographic trends of the community within ${selectedDriveTime} minutes of ${selectedSchool?.name || 'the selected school'}`}
                icon={PeopleOutlined}
              >
                <Suspense fallback={<Skeleton variant="rectangular" height={600} />}>
                  <CommunityHeadlines
                    selectedSchool={selectedSchool}
                    esriData={esriData}
                    selectedDriveTime={selectedDriveTime}
                    schoolsEnrollmentData={schoolsEnrollmentData}
                    filteredSchools={filteredSchools}
                    selectedGrades={selectedGrades}
                    governanceFilter={governanceFilter} 
                  />
                </Suspense>
              </AccordionSection>

              <AccordionSection
                id="enrollmentHeadlines"
                title="Enrollment Trends"
                summary={`Historical enrollment and demographic trends for ${selectedSchool?.name || 'the selected school'}`}
                icon={GroupsOutlined}
              >
                <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                  <EnrollmentHeadlines
                    selectedSchool={selectedSchool}
                    filteredSchools={filteredSchools}
                    schoolsEnrollmentData={schoolsEnrollmentData}
                    selectedGrades={selectedGrades}
                    governanceFilter={governanceFilter} 
                  />
                </Suspense>
              </AccordionSection>

              <AccordionSection
                id="comparisonHeadlines"
                title="Nearby Schools Comparison"
                summary={`Interactive analysis of ${selectedSchool?.name || 'the selected school'} and nearby schools`}
                icon={CompareArrowsOutlined}
              >
                <Suspense fallback={<Skeleton variant="rectangular" height={600} />}>
                  <ComparisonHeadlines
                    selectedSchool={selectedSchool}
                    filteredSchools={filteredSchools}
                    esriData={esriData}
                    visibleDriveTimes={[selectedDriveTime]}
                    schoolsEnrollmentData={schoolsEnrollmentData}
                    governanceFilter={governanceFilter}
                    selectedGrades={selectedGrades}
                    schoolTypeFilter={schoolTypeFilter}
                    PopupComponent={SchoolPopup}
                    nearbySchools={nearbySchools}
                  />
                </Suspense>
              </AccordionSection>

              <AccordionSection
                id="nowWhat"
                title="Now What?"
                summary="Continue your analysis with these related tools"
                icon={Explore}
              >
                <Suspense fallback={<Skeleton variant="rectangular" height={600} />}>
                  <NowWhatSection 
                    sectionIcon={Explore}
                    actions={actions}
                  />
                  </Suspense>
              </AccordionSection>

            </AccordionContainer>
          </LoadingErrorWrapper>
        )}
      </LoadingErrorWrapper>
    </PageLayout>
  );
}

export default GeographicExplorer;