/**
 * School utility functions for filtering, calculations, and formatting
 */

// Helper to convert grade range string to array of grades
const parseGradeRange = (gradeRange) => {
  if (!gradeRange) return [];
  
  // Handle the "Grades 5-8" format
  const gradesMatch = gradeRange.match(/Grades? (\d+)-(\d+)/);
  if (gradesMatch) {
    const startNum = parseInt(gradesMatch[1]);
    const endNum = parseInt(gradesMatch[2]);
    
    const grades = [];
    for (let i = startNum; i <= endNum; i++) {
      grades.push(i.toString());
    }
    return grades;
  }

  // Handle "Pre-Kindergarten - Grade 4" format
  const preKMatch = gradeRange.match(/(?:Pre-Kindergarten - )?Grade (\d+)/);
  if (preKMatch) {
    const endGrade = parseInt(preKMatch[1]);
    const grades = ['Kindergarten'];
    for (let i = 1; i <= endGrade; i++) {
      grades.push(i.toString());
    }
    
    // Add Pre-K if it exists in the original string
    if (gradeRange.includes('Pre-Kindergarten')) {
      grades.unshift('Pre-Kindergarten');
    }
    
    return grades;
  }

  return [];
};

export const filterSchools = (schools, {
  selectedGrades,
  governanceFilter,
  schoolTypeFilter
}, enrollmentData) => {
  if (!schools) return [];

  return schools.filter(school => {
    // Find matching enrollment data
    const schoolEnrollment = enrollmentData?.find(e => e.ncessch === school.ncessch);

    // School type filter
    if (schoolTypeFilter !== 'All') {
      const schoolType = schoolEnrollment?.school_type || school.school_type;
      if (schoolType !== schoolTypeFilter) {
        return false;
      }
    }

    // Governance filter
    if (governanceFilter !== 'All') {
      const isCharter = school.charter === 'Yes';
      if ((governanceFilter === 'Charter' && !isCharter) || 
          (governanceFilter === 'District' && isCharter)) {
        return false;
      }
    }

    // Grade filter - check for overlap with selected grades
    if (selectedGrades && selectedGrades.length > 0) {
      const schoolGrades = parseGradeRange(school.grade_range)
        .filter(grade => grade !== 'Pre-Kindergarten'); // Exclude Pre-K from comparison
      
      const hasOverlap = selectedGrades.some(grade => 
        schoolGrades.includes(grade)
      );

      if (!hasOverlap) {
        return false;
      }
    }

    return true;
  });
};

export const getSchoolGrades = (gradeRange) => {
  const grades = parseGradeRange(gradeRange);
  // Filter out Pre-K from the returned grades
  return grades.filter(grade => grade !== 'Pre-Kindergarten');
};

export const getAvailableSchoolTypes = (enrollmentData) => {
  if (!enrollmentData?.length) {
    return ['All'];
  }

  const types = new Set(['All']);
  enrollmentData.forEach(school => {
    if (school.school_type) {
      types.add(school.school_type);
    }
  });

  return Array.from(types).sort();
};

export const calculateEnrollment = (enrollmentByGrade, selectedGrades) => {
  if (!enrollmentByGrade || !selectedGrades || selectedGrades.length === 0) {
    return 0;
  }

  let total = 0;
  selectedGrades.forEach(grade => {
    if (grade === 'Kindergarten') {
      total += enrollmentByGrade['Kindergarten'] || 0;
    } else {
      // For numeric grades, we need to prepend "Grade "
      const gradeKey = `Grade ${grade}`;
      total += enrollmentByGrade[gradeKey] || 0;
    }
  });

  return total;
};

export const calculateMarketShares = (filteredSchools, schoolsEnrollmentData, selectedGrades) => {
  if (!filteredSchools?.length || !schoolsEnrollmentData?.length || !selectedGrades?.length) {
    return {};
  }

  // Deduplicate schools
  const uniqueSchools = deduplicateSchools(filteredSchools);

  const years = ['2017', '2022'];
  const marketShares = {};

  years.forEach(year => {
    const yearKey = year === '2017' ? 'five_years_ago' : 'current';
    
    const totalEnrollment = uniqueSchools.reduce((sum, school) => {
      const schoolData = schoolsEnrollmentData.find(s => s.ncessch === school.ncessch);
      if (!schoolData?.enrollment_by_grade?.[yearKey]) return sum;
      return sum + calculateEnrollment(schoolData.enrollment_by_grade[yearKey], selectedGrades);
    }, 0);

    uniqueSchools.forEach(school => {
      const schoolData = schoolsEnrollmentData.find(s => s.ncessch === school.ncessch);
      marketShares[school.ncessch] = marketShares[school.ncessch] || {};
      
      if (!schoolData?.enrollment_by_grade?.[yearKey]) {
        marketShares[school.ncessch][year] = 0;
        return;
      }

      const schoolEnrollment = calculateEnrollment(
        schoolData.enrollment_by_grade[yearKey], 
        selectedGrades
      );

      marketShares[school.ncessch][year] = totalEnrollment > 0 ? 
        schoolEnrollment / totalEnrollment : 0;
    });
  });

  // Calculate changes
  uniqueSchools.forEach(school => {
    if (marketShares[school.ncessch]) {
      marketShares[school.ncessch].change = 
        (marketShares[school.ncessch]['2022'] || 0) - 
        (marketShares[school.ncessch]['2017'] || 0);
    }
  });

  return marketShares;
};

// Formatting helpers
export const formatMarketShare = (value, decimals = 1) => {
  if (value === null || value === undefined) return '0';
  return (value * 100).toFixed(decimals);
};

// Age conversion
export const gradeToAge = (grade) => {
  if (grade === 'Kindergarten') return 5;
  return parseInt(grade) + 5;
};

export const getAgeRangeFromGrades = (grades) => {
  if (!grades || grades.length === 0) return { min: 4, max: 17 }; // Default age range

  const ages = grades.map(grade => gradeToAge(grade));
  return {
    min: Math.min(...ages),
    max: Math.max(...ages)
  };
};

// New function to filter age data by grade range
export const filterAgeData = (ageData, selectedGrades) => {
  if (!ageData || !selectedGrades || selectedGrades.length === 0) return ageData;

  const { min, max } = getAgeRangeFromGrades(selectedGrades);
  const filteredData = {};

  // Filter each year's data
  Object.entries(ageData).forEach(([year, counts]) => {
    filteredData[year] = counts.filter((_, index) => {
      const age = index + 4; // Age data starts at age 4
      return age >= min && age <= max;
    });
  });

  return filteredData;
};

// Updated calculateTotalChildren to handle grade filtering
export const calculateTotalChildren = (yearData, selectedGrades) => {
  if (!yearData) return 0;
  if (!selectedGrades || selectedGrades.length === 0) return yearData.reduce((sum, count) => sum + count, 0);

  const { min, max } = getAgeRangeFromGrades(selectedGrades);
  return yearData.reduce((sum, count, index) => {
    const age = index + 4; // Age data starts at age 4
    return age >= min && age <= max ? sum + count : sum;
  }, 0);
};

// Utility for deduplicating schools array
export const deduplicateSchools = (schools) => {
  if (!schools?.length) return [];
  return Array.from(new Map(schools.map(school => [school.ncessch, school])).values());
};

// Calculating race percentages
export const calculateRacePercentage = (school, field) => {
  const raceCount = school?.race_data?.[field] || 0;
  const totalRaceCount = Object.values(school?.race_data || {}).reduce((sum, count) => sum + (count || 0), 0);
  return totalRaceCount > 0 ? (raceCount / totalRaceCount * 100) : 0;
};

// Race mapping
export const raceFieldMap = {
  'Asian': 'asian',
  'Black': 'black',
  'Hispanic': 'hispanic',
  'Multi': 'two_or_more_races',
  'Nat. Am.': 'american_indian',
  'Pac. Isl.': 'pacific_islander',
  'White': 'white'
};

// Race categories mapping
export const RACE_CATEGORIES = {
  american_indian: 'Native American',
  asian: 'Asian',
  black: 'Black',
  hispanic: 'Hispanic',
  pacific_islander: 'Pacific Islander',
  white: 'White',
  two_or_more_races: 'Multiracial'
};

// Calculate the total enrollment
export const calculateTotalEnrollment = (schools, schoolsEnrollmentData, selectedGrades) => {
  const enrollmentData = schools.map(school => 
    schoolsEnrollmentData.find(d => d.ncessch === school.ncessch)
  ).filter(Boolean);

  const oldEnrollment = enrollmentData.reduce((sum, school) => 
    sum + calculateEnrollment(school.enrollment_by_grade?.five_years_ago, selectedGrades), 0);
  const newEnrollment = enrollmentData.reduce((sum, school) => 
    sum + calculateEnrollment(school.enrollment_by_grade?.current, selectedGrades), 0);

  console.log('calculateTotalEnrollment:', {
    inputSchools: schools.length,
    matchedEnrollmentData: enrollmentData.length,
    oldEnrollment,
    newEnrollment
  });
  
  return {
    oldEnrollment,
    newEnrollment,
    percentChange: oldEnrollment > 0 ? ((newEnrollment - oldEnrollment) / oldEnrollment) * 100 : 0
  };
};

//  Calculate the race compitions
export const calculateDemographicComposition = (school) => {
  if (!school) return [];
  
  const results = Object.entries(RACE_CATEGORIES).map(([key, label]) => {
    // Use calculateRacePercentage for consistency
    const schoolPct = calculateRacePercentage(school, key);
    const schoolCurrent = school.race_data?.[key] || 0;
    const schoolOld = school.race_data_five_years_ago?.[key] || 0;
    const schoolChange = schoolOld > 0 ? ((schoolCurrent - schoolOld) / schoolOld * 100) : 0;

    return {
      category: label,
      value: schoolPct,
      count: schoolCurrent,
      oldCount: schoolOld,
      change: Math.round(schoolChange * 10) / 10
    };
  });

  return results.filter(d => d.value >= 5); // 5% threshold
};

// Color constants for visualization
export const CHART_COLORS = {
  GROWING: 'rgb(34, 197, 94)',  // green
  DECLINING: 'rgb(239, 68, 68)', // red
  NEUTRAL: 'rgb(156, 163, 175)', // grey
  PRIMARY: 'rgb(25, 118, 210)'   // blue
};

export const CHART_OPACITY = 0.8;
export const SIGNIFICANT_CHANGE_THRESHOLD = 5;

/**
 * Get color for trend based on percentage change
 * @param {number} percentChange - The percentage change
 * @returns {string} RGB color string
 */
export const getTrendColor = (percentChange) => {
  if (Math.abs(percentChange) < SIGNIFICANT_CHANGE_THRESHOLD) {
    return CHART_COLORS.NEUTRAL;
  }
  return percentChange > 0 ? CHART_COLORS.GROWING : CHART_COLORS.DECLINING;
};

/**
 * Convert RGB color to RGBA with opacity
 * @param {string} color - RGB color string
 * @param {number} opacity - Opacity value between 0 and 1
 * @returns {string} RGBA color string
 */
export const getColorWithOpacity = (color, opacity = CHART_OPACITY) => {
  return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
};

// Helper to get background color for trend summaries
export const getTrendBackgroundColor = (color, opacity = 0.1) => {
  return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
};

const capitalizeWord = (word) => {
  // List of known acronyms and words that should remain uppercase or have specific capitalization
  const specialCases = {
    'KIPP': 'KIPP',
    'IDEA': 'IDEA',
    'DSST:': 'DSST:',
    'STRIVE': 'STRIVE',
    'ISD': 'ISD',
    'STEM': 'STEM',
    'PK': 'PK',
    'PRE-K': 'Pre-K',
    'K-8': 'K-8',
    'K-12': 'K-12',
    '3D': '3D',
  };
  
  if (specialCases[word.toUpperCase()]) {
    return specialCases[word.toUpperCase()];
  }
  
  // List of lowercase words (articles, conjunctions, prepositions)
  const lowercaseWords = ['a', 'an', 'the', 'and', 'but', 'or', 'for', 'nor', 'on', 'at', 'to', 'from', 'by', 'of'];
  
  if (lowercaseWords.includes(word.toLowerCase())) {
    return word.toLowerCase();
  }
  
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

const formatSchoolName = (name) => {
  return name
    .split(' ')
    .map((word, index) => {
      // Always capitalize the first and last word, regardless of what they are
      if (index === 0 || index === name.split(' ').length - 1) {
        return capitalizeWord(word);
      }
      return capitalizeWord(word);
    })
    .join(' ');
};

export { formatSchoolName, capitalizeWord };

/**
 * Format a list of grades ensuring proper sorting and consistent display
 * @param {string|array} grades - Comma-separated string or array of grades
 * @param {boolean} useRangeFormat - Whether to use range format (e.g., "Grades 6-12") for consecutive grades
 * @returns {string} Formatted grade list
 */
export const formatGradeList = (grades, useRangeFormat = false) => {
  if (!grades) return '';
  
  // Convert string input to array if needed
  const gradeArray = Array.isArray(grades) ? [...grades] : grades.split(', ');
  
  // Sort function for grades
  const sortGrades = (a, b) => {
    if (a === 'Pre-Kindergarten') return -1;
    if (b === 'Pre-Kindergarten') return 1;
    if (a === 'Kindergarten' || a === 'K') return -1;
    if (b === 'Kindergarten' || b === 'K') return 1;
    
    const numA = parseInt(a.replace('Grade ', ''));
    const numB = parseInt(b.replace('Grade ', ''));
    return numA - numB;
  };

  // Sort the grades
  gradeArray.sort(sortGrades);

  // Standardize format (convert "Grade X" to just "X" and "Kindergarten" to "K")
  const standardizedGrades = gradeArray.map(grade => {
    if (grade === 'Kindergarten') return 'K';
    if (grade === 'Pre-Kindergarten') return 'Pre-K';
    return grade.replace('Grade ', '');
  });

  if (useRangeFormat) {
    // Find consecutive sequences
    const numericGrades = standardizedGrades
      .filter(g => !['Pre-K', 'K'].includes(g))
      .map(g => parseInt(g));
    
    if (numericGrades.length > 2) {
      const min = Math.min(...numericGrades);
      const max = Math.max(...numericGrades);
      const isConsecutive = max - min === numericGrades.length - 1;
      
      if (isConsecutive) {
        const prefix = standardizedGrades.filter(g => ['Pre-K', 'K'].includes(g));
        if (prefix.length) {
          return `${prefix.join(', ')}, Grades ${min}-${max}`;
        }
        return `Grades ${min}-${max}`;
      }
    }
  }

  return standardizedGrades.join(', ');
};

/**
 * Parse grade data from enrollment data into a formatted string
 * @param {Object} enrollmentData - Enrollment data object
 * @param {string} timepoint - Which timepoint to use ('current' or 'comparison')
 * @returns {string} Formatted grade string
 */
export const getGradesFromEnrollment = (enrollmentData, timepoint = 'current') => {
  if (!enrollmentData?.enrollment_by_grade?.[timepoint]) return 'N/A';
  
  const grades = Object.entries(enrollmentData.enrollment_by_grade[timepoint])
    .filter(([_, enrollment]) => enrollment > 0)
    .map(([grade]) => grade);
  
  return formatGradeList(grades, true); // Use range format when appropriate
};

export const getSchoolTimeframe = (schoolData) => {
  if (!schoolData?.enrollment_by_grade) {
    console.log('No enrollment data found:', schoolData);
    return null;
  }

  // Get current (2023-24) grades with enrollment
  const currentGrades = Object.entries(schoolData.enrollment_by_grade.current || {})
    .filter(([_, value]) => value > 0)
    .map(([grade]) => grade)
    .sort();

  // Check if school has 2019 data
  const hasComparisonData = schoolData.enrollment_by_grade.comparison && 
    Object.values(schoolData.enrollment_by_grade.comparison)
      .some(value => value > 0);

  // Format grades for display
  const formatInternalGrades = (grades) => {
    return formatGradeList(
      grades
        .map(grade => grade === 'Kindergarten' ? 'K' : grade.replace('Grade ', ''))
        .join(', ')
    );
  };

  let startYear;
  let startGrades;
  let endGrades = currentGrades;

  if (hasComparisonData) {
    startYear = 2019;
    startGrades = Object.entries(schoolData.enrollment_by_grade.comparison)
      .filter(([_, value]) => value > 0)
      .map(([grade]) => grade)
      .sort();
  } else {
    const yearKeys = Object.keys(schoolData.enrollment_by_grade)
      .filter(key => key !== 'current' && key !== 'comparison' && !isNaN(parseInt(key)))
      .sort();

    if (yearKeys.length > 0) {
      startYear = parseInt(yearKeys[0]);
      startGrades = Object.entries(schoolData.enrollment_by_grade[yearKeys[0]])
        .filter(([_, value]) => value > 0)
        .map(([grade]) => grade)
        .sort();
    } else {
      startYear = 2023;
      startGrades = currentGrades;
    }
  }

  const hasExpandedGrades = startGrades.length < endGrades.length;

  return {
    startYear,
    endYear: 2024,
    startLabel: `${startYear}-${(startYear + 1).toString().slice(-2)}`,
    endLabel: '2023-24',
    yearCount: 2024 - startYear,
    hasComparison: hasComparisonData || startYear < 2023,
    startGrades: formatInternalGrades(startGrades),
    endGrades: formatInternalGrades(endGrades),
    hasExpandedGrades,
    gradeChange: {
      start: startGrades.length,
      end: endGrades.length,
      difference: endGrades.length - startGrades.length
    }
  };
};

// Helper for getting comparison data
export const getComparisonData = (schoolData, type = 'enrollment') => {
  if (!schoolData?.enrollment_by_grade) return null;

  const comparison = schoolData.enrollment_by_grade.comparison || {};
  const hasComparisonData = Object.values(comparison).some(value => value > 0);

  // If has valid comparison data, use it
  if (hasComparisonData) {
    return comparison;
  }

  // Otherwise return empty/null to indicate no historical data
  return null;
};

// Helper to format date range text
export const getDateRangeText = (timeframe, schoolName) => {
  if (timeframe?.hasComparison) {
    return "over the past five years";
  }
  return `since ${timeframe?.startLabel || '2023'}`;
};

// Helper to format academic year
export const formatAcademicYear = (year) => {
  const startYear = parseInt(year);
  const endYear = startYear + 1;
  return `${startYear}-${endYear.toString().slice(-2)}`;
};

// Add these new utility functions to schoolUtils.js

/**
 * Get the earliest year with enrollment data for a school
 * @param {Object} schoolData - The school's enrollment data
 * @returns {Object} The earliest year's enrollment data or null
 */
export const getEarliestEnrollmentData = (schoolData) => {
  if (!schoolData?.enrollment_by_grade) return null;

  // Check for comparison (2019) data first
  const comparison = schoolData.enrollment_by_grade.comparison || {};
  if (Object.values(comparison).some(value => value > 0)) {
    return comparison;
  }

  // Get all years excluding 'current' and 'comparison'
  const yearKeys = Object.keys(schoolData.enrollment_by_grade)
    .filter(key => key !== 'current' && key !== 'comparison' && !isNaN(parseInt(key)))
    .sort();

  return yearKeys.length > 0 ? schoolData.enrollment_by_grade[yearKeys[0]] : null;
};

/**
 * Calculate enrollment statistics for a school or group of schools
 * @param {Object|Array} schools - Single school data object or array of school data objects
 * @param {Array} selectedGrades - Array of selected grade levels
 * @returns {Object} Enrollment statistics
 */
export const calculateEnrollmentStats = (schools, selectedGrades) => {
  const schoolArray = Array.isArray(schools) ? schools : [schools];
  
  const stats = schoolArray.reduce((acc, school) => {
    const comparisonData = getEarliestEnrollmentData(school);
    const currentData = school?.enrollment_by_grade?.current || {};

    const oldEnrollment = calculateEnrollment(comparisonData || {}, selectedGrades);
    const newEnrollment = calculateEnrollment(currentData, selectedGrades);

    return {
      oldEnrollment: acc.oldEnrollment + (Number.isFinite(oldEnrollment) ? oldEnrollment : 0),
      newEnrollment: acc.newEnrollment + (Number.isFinite(newEnrollment) ? newEnrollment : 0),
      schoolCount: acc.schoolCount + 1
    };
  }, { oldEnrollment: 0, newEnrollment: 0, schoolCount: 0 });

  const validComparison = stats.oldEnrollment > 0;

  return {
    ...stats,
    percentChange: validComparison ? ((stats.newEnrollment - stats.oldEnrollment) / stats.oldEnrollment) * 100 : 0,
    hasValidComparison: validComparison
  };
};

/**
 * Calculate comprehensive enrollment trends for a school and its comparison groups
 * @param {Object} params - Parameters object
 * @returns {Object} Enrollment trends data
 */
export const calculateEnrollmentTrends = ({
  selectedSchool,
  selectedSchoolData,
  filteredSchools,
  schoolsEnrollmentData,
  selectedGrades,
  governanceFilter
}) => {
  if (!selectedSchool || !selectedSchoolData || !filteredSchools?.length || !schoolsEnrollmentData?.length || !selectedGrades?.length) {
    return null;
  }

  const timeframe = getSchoolTimeframe(selectedSchoolData);
  
  // Get filtered comparison schools
  const otherSchools = filteredSchools
    .filter(school => school.ncessch !== selectedSchool.ncessch)
    .map(school => schoolsEnrollmentData.find(d => d.ncessch === school.ncessch))
    .filter(Boolean);

  const charterSchools = otherSchools.filter(s => s?.charter === "Yes");
  const districtSchools = otherSchools.filter(s => s?.charter !== "Yes");

  // Calculate stats for each group
  const selectedStats = calculateEnrollmentStats(selectedSchoolData, selectedGrades);
  const totalStats = calculateEnrollmentStats(otherSchools, selectedGrades);
  const charterStats = calculateEnrollmentStats(charterSchools, selectedGrades);
  const districtStats = calculateEnrollmentStats(districtSchools, selectedGrades);

  return {
    selectedSchool: {
      ...selectedStats,
      timeframe
    },
    total: totalStats,
    charter: charterStats,
    district: districtStats,
    isNotable: Math.abs((selectedStats.percentChange || 0) - (totalStats.percentChange || 0)) >= 5,
    difference: (selectedStats.percentChange || 0) - (totalStats.percentChange || 0)
  };
};

/**
 * Get appropriate comparison text based on difference
 * @param {number} difference - The difference in percentage points
 * @param {boolean} isNotable - Whether the difference is notable
 * @returns {string} Comparison text
 */
export const getComparisonText = (difference, isNotable) => {
  if (Math.abs(difference) < 1) return 'similar to';
  if (!isNotable) return difference > 0 ? 'higher than' : 'lower than';
  return difference > 0 ? 'notably higher than' : 'notably lower than';
};

/**
 * Get background color for trend container based on change percentage
 * @param {number} change - The percentage change
 * @returns {string} Hex color code for background
 */
export const getTrendBackgroundByChange = (change) => {
  if (!change) return '#f5f5f5';
  if (change >= 5) return '#e8f5e9';
  if (change <= -5) return '#ffebee';
  return '#f5f5f5';
};

/**
 * Add opacity to a base color for trend backgrounds
 * @param {string} color - Base color
 * @param {number} opacity - Opacity value between 0 and 1
 * @returns {string} RGBA color string
 */
export const getTrendBackgroundWithOpacity = (color, opacity = 0.1) => {
  return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
};