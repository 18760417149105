import { useState } from 'react';
import { apiFetch } from './api';

/**
 * School API endpoints
 */
export const schoolsApi = {
  async getAllSchools() {
    return apiFetch('/schools');
  },

  async getMarketShareData(schoolId) {
    return apiFetch(`/school/${schoolId}/esri-market-share`);
  },

  getEnrollmentProjections: async (schoolId) => {
    try {
      return await apiFetch(`/school/${schoolId}/enrollment-projections`);
    } catch (error) {
      console.error('Enrollment projections error:', {
        status: error.status,
        message: error.message,
        response: error.response
      });

      if (error.status === 401 || error.status === 403) {
        throw new Error('You do not have permission to view this school\'s projections');
      } else if (error.status === 404) {
        throw new Error('School projections not found');
      } else if (typeof error.response === 'string' && error.response.includes('<!DOCTYPE')) {
        throw new Error('Authentication error. Please try logging in again.');
      } else {
        throw new Error(error.message || 'Failed to fetch enrollment projections');
      }
    }
  },

  // Updated to handle both current and comparison nearby schools
  async getNearbySchools(schoolId, driveTime, type = 'current') {
    return apiFetch(`/school/${schoolId}/nearby-schools/${driveTime}?type=${type}`);
  },

  async getSchoolsEnrollmentData(schoolIds, selectedSchoolId) {
    console.log('API call - getSchoolsEnrollmentData:', {
      requestedSchools: schoolIds,
      selectedSchool: selectedSchoolId
    });
    
    try {
      const data = await apiFetch('/schools-enrollment-by-grade', {
        method: 'POST',
        body: JSON.stringify({ 
          ncesschList: schoolIds,
          selectedSchool: selectedSchoolId
        })
      });
      
      console.log('API response - getSchoolsEnrollmentData:', {
        schoolsRequested: schoolIds.length,
        schoolsReceived: data.length,
        selectedSchoolIncluded: selectedSchoolId ? data.some(d => d.ncessch === selectedSchoolId) : false
      });
      
      return data;
    } catch (error) {
      console.error('Error fetching enrollment data:', error);
      throw error;
    }
  },

  // Updated to fetch both current and comparison nearby schools
  async getAllNearbySchools(schoolId) {
    try {
      const results = {
        current: {},
        comparison: {}
      };
      
      for (const driveTime of [5, 10, 15]) {
        // Fetch current nearby schools
        results.current[driveTime] = await schoolsApi.getNearbySchools(schoolId, driveTime, 'current');
        
        // Fetch comparison nearby schools
        results.comparison[driveTime] = await schoolsApi.getNearbySchools(schoolId, driveTime, 'comparison');
      }
      
      console.log('Nearby schools response:', results);
      return results;
    } catch (error) {
      console.error('Error fetching nearby schools:', error);
      throw error;
    }
  }
};

/**
 * Hook for managing API calls with loading and error states
 */
export function useApiCall(apiCall, onSuccess, onError, showLoading = true) {
  const [loading, setLoading] = useState(showLoading);
  const [error, setError] = useState(null);

  const execute = async (...args) => {
    try {
      setLoading(true);
      setError(null);

      const result = await apiCall(...args);
      
      if (!result || (typeof result === 'string' && result.includes('<!DOCTYPE'))) {
        throw new Error('Invalid response received');
      }

      if (onSuccess) onSuccess(result);
      return result;
    } catch (err) {
      console.error('API call failed:', {
        error: err,
        status: err.status,
        message: err.message
      });

      let errorMessage = err.message;
      
      if (err.status === 401 || 
          err.message.includes('unauthorized') || 
          err.message.includes('<!DOCTYPE')) {
        errorMessage = 'Your session has expired. Please log in again.';
      }

      setError(errorMessage);
      if (onError) onError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { execute, loading, error };
}

export { apiFetch };