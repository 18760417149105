import { refreshToken } from './auth';

const API_URL = process.env.REACT_APP_BACKEND_URL;

export async function apiFetch(endpoint, options = {}) {
  const url = `${API_URL}${endpoint}`;
  
  // Wait a bit to ensure token is available after login
  const getToken = async () => {
    let attempts = 0;
    while (attempts < 3) {
      const token = localStorage.getItem('access_token');
      if (token) return token;
      await new Promise(resolve => setTimeout(resolve, 500));
      attempts++;
    }
    throw new Error('No authentication token available');
  };

  try {
    const token = await getToken();
    
    options.headers = {
      ...options.headers,
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    let response = await fetch(url, options);

    if (response.status === 401) {
      const refreshed = await refreshToken();
      if (refreshed) {
        const newToken = localStorage.getItem('access_token');
        options.headers['Authorization'] = `Bearer ${newToken}`;
        response = await fetch(url, options);
      } else {
        throw new Error('Session expired. Please log in again.');
      }
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error('API fetch error:', error);
    throw error;
  }
}
