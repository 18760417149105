import React from 'react';
import { 
  Container, 
  CircularProgress, 
  Alert, 
  Skeleton, 
  Box, 
  Typography,
  Grid 
} from '@mui/material';

export const LoadingErrorWrapper = ({ 
  loading, 
  error, 
  children, 
  loadingComponent,
  errorComponent,
  initialLoading = false,
  skeletonCount = 3,
  containerWidth = "xl"
}) => {
  // Initial loading state (when fetching schools list)
  if (initialLoading && loading) {
    return (
      <Container maxWidth={containerWidth} sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
          <Skeleton width={300} />
        </Typography>
        <Skeleton variant="rectangular" height={56} sx={{ mb: 4 }} />
        <Grid container spacing={3}>
          {[...Array(skeletonCount)].map((_, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Skeleton variant="rectangular" height={200} />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }

  // Error state
  if (error) {
    return errorComponent || (
      <Container maxWidth="lg">
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  // Content loading state (when fetching additional data)
  if (loading && !initialLoading) {
    return loadingComponent || (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
        <Typography variant="body2" sx={{ ml: 2 }}>
          Loading data...
        </Typography>
      </Box>
    );
  }

  return children;
};

export default LoadingErrorWrapper;