import React from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ActionButton = ({ icon: Icon, title, description, onClick }) => (
  <Button
    variant="outlined"
    fullWidth
    onClick={onClick}
    sx={{
      p: 3,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      textAlign: 'left',
      textTransform: 'none'
    }}
  >
    <Icon sx={{ fontSize: 40, mb: 2, color: 'primary.main' }} />
    <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
      {title}
    </Typography>
    <Typography variant="body2" color="text.secondary">
      {description}
    </Typography>
  </Button>
);

const NowWhatSection = ({
  sectionIcon,
  actions = []
}) => {
  const navigate = useNavigate();

  return (
      <Grid container spacing={3}>
        {actions.map((action, index) => (
          <Grid item xs={12} md={4} key={index}>
            <ActionButton
              icon={action.icon}
              title={action.title}
              description={action.description}
              onClick={() => navigate(action.path)}
            />
          </Grid>
        ))}
      </Grid>
  );
};

export default NowWhatSection;