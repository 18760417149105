import React, { useMemo } from 'react';
import { Typography, Box, Divider, Paper, Grid } from '@mui/material';
import { TrendingUp, TrendingDown } from '@mui/icons-material';
import { formatSchoolName } from '../../utils/nameFormatter';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip } from 'chart.js';
import { calculateEnrollment } from '../../utils/schoolUtils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const SchoolPopup = ({ school, schoolData }) => {
  const enrollmentStats = useMemo(() => {
    if (!schoolData?.enrollment_by_grade?.current) {
      return { current: 0, change: 0, hasComparison: false };
    }

    const allGrades = ['Kindergarten'];
    for (let i = 1; i <= 12; i++) {
      allGrades.push(`${i}`);
    }

    const currentEnrollment = calculateEnrollment(
      schoolData.enrollment_by_grade.current,
      allGrades
    );
    
    const comparisonEnrollment = schoolData.enrollment_by_grade.comparison ? 
      calculateEnrollment(schoolData.enrollment_by_grade.comparison, allGrades) : null;

    const hasComparison = comparisonEnrollment !== null && comparisonEnrollment > 0;
    const percentChange = hasComparison ? 
      ((currentEnrollment - comparisonEnrollment) / comparisonEnrollment) * 100 : 0;

    return {
      current: currentEnrollment,
      comparison: comparisonEnrollment,
      change: percentChange,
      hasComparison
    };
  }, [schoolData]);

  const raceColors = {
    'hispanic': 'rgba(255, 192, 203, 0.8)',
    'white': 'rgba(173, 216, 230, 0.8)',
    'black': 'rgba(255, 223, 186, 0.8)',
    'asian': 'rgba(176, 224, 230, 0.8)',
    'pacific_islander': 'rgba(221, 160, 221, 0.8)',
    'american_indian': 'rgba(255, 218, 185, 0.8)',
    'two_or_more_races': 'rgba(221, 221, 221, 0.8)'
  };

  const getRaceData = () => {
    if (!schoolData?.race_data) return null;

    const total = Object.values(schoolData.race_data).reduce((sum, value) => sum + value, 0);
    if (total === 0) return null;

    const displayNames = {
      'american_indian': 'Native American',
      'asian': 'Asian',
      'black': 'Black',
      'hispanic': 'Hispanic',
      'pacific_islander': 'Pacific Islander',
      'white': 'White',
      'two_or_more_races': 'Two Or More Races'
    };

    const processedData = Object.entries(schoolData.race_data)
      .map(([key, value]) => ({
        category: displayNames[key] || key,
        value: Math.round((value / total) * 100)
      }))
      .filter(item => item.value > 0)
      .sort((a, b) => b.value - a.value);

    return {
      labels: processedData.map(d => d.category),
      datasets: [{
        data: processedData.map(d => d.value),
        backgroundColor: processedData.map(d => 
          raceColors[
            Object.entries(displayNames)
              .find(([_, value]) => value === d.category)?.[0] || 
              d.category.toLowerCase()
          ] || 'rgba(200, 200, 200, 0.8)'
        ),
        borderWidth: 0,
        borderRadius: 4,
        barPercentage: 0.8,
        categoryPercentage: 0.9
      }]
    };
  };

  const chartOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false
      }
    },
    scales: {
      x: {
        display: false,
        beginAtZero: true,
        max: 100,
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback: function(value, index) {
            return this.getLabelForValue(value);
          },
          font: {
            size: 11
          }
        }
      }
    }
  };

  const raceData = getRaceData();

  return (
    <Box sx={{ width: 300 }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0.5 }}>
        {formatSchoolName(school.name)}
      </Typography>
      <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
        {school.charter === 'Yes' ? 'Charter' : 'District'} School | {school.grade_range}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <Paper elevation={3} sx={{ 
            p: 2, 
            textAlign: 'center', 
            height: 80, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center' 
          }}>
            <Typography variant="h6">{enrollmentStats.current.toLocaleString()}</Typography>
            <Typography variant="caption" color="text.secondary">23-24 Enrollment</Typography>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper elevation={3} sx={{ 
            p: 2, 
            textAlign: 'center', 
            height: 80, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center',
            bgcolor: enrollmentStats.change > 0 ? 'rgba(0, 200, 83, 0.08)' : 
                     enrollmentStats.change < 0 ? 'rgba(211, 47, 47, 0.08)' : 'grey.50'
          }}>
            {enrollmentStats.hasComparison ? (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
                  {enrollmentStats.change > 0 ? (
                    <TrendingUp sx={{ color: 'success.main' }} fontSize="small" />
                  ) : enrollmentStats.change < 0 ? (
                    <TrendingDown sx={{ color: 'error.main' }} fontSize="small" />
                  ) : null}
                  <Typography variant="h6">
                    {enrollmentStats.change > 0 ? '+' : ''}{Math.round(enrollmentStats.change)}%
                  </Typography>
                </Box>
                <Typography variant="caption" color="text.secondary">Since 2019</Typography>
              </>
            ) : (
              <Typography variant="caption" color="text.secondary">New School</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Divider sx={{ mb: 2 }} />
      <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold' }}>
        2023-24 Demographics (%)
      </Typography>
      {raceData ? (
        <Box sx={{ height: 200, width: '100%' }}>
          <Bar 
            data={raceData} 
            options={chartOptions}
          />
        </Box>
      ) : (
        <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
          Demographic data not available for this school
        </Typography>
      )}
    </Box>
  );
};

export default SchoolPopup;