import L from 'leaflet';
import { calculateEnrollment } from '../utils/schoolUtils';

/**
 * Custom icon creator for both map types
 */
export const customIcon = ({ color, isSelected = false, isCharter = false, opacity = 1 }) => {
  const size = isSelected ? 40 : 30;
  
  const locationPath = "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z";
  const schoolPath = "M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z";

  const iconSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24" style="opacity: ${opacity}">
      <path fill="${color}" d="${isCharter ? schoolPath : locationPath}"/>
    </svg>
  `;

  return L.divIcon({
    html: iconSvg,
    className: `custom-icon ${isCharter ? 'SchoolOutlined' : 'LocationOnOutlined'}`,
    iconSize: [size, size],
    iconAnchor: [size/2, size],
    popupAnchor: [0, -size/2],
  });
};

/**
 * Calculate enrollment change for a school
 */
const calculateEnrollmentChange = (schoolData) => {
  if (!schoolData?.enrollment_by_grade) return { percentChange: 0, hasData: false };

  const currentGrades = Object.keys(schoolData.enrollment_by_grade.current || {});
  const comparisonGrades = Object.keys(schoolData.enrollment_by_grade.comparison || {});
  
  const currentEnrollment = calculateEnrollment(schoolData.enrollment_by_grade.current || {}, currentGrades);
  const comparisonEnrollment = calculateEnrollment(schoolData.enrollment_by_grade.comparison || {}, comparisonGrades);

  if (!currentEnrollment && !comparisonEnrollment) return { percentChange: 0, hasData: false };
  
  const percentChange = comparisonEnrollment > 0 ? 
    ((currentEnrollment - comparisonEnrollment) / comparisonEnrollment) * 100 : 0;

  console.log('Enrollment Calculation:', {
    school: schoolData.name,
    current: currentEnrollment,
    comparison: comparisonEnrollment,
    percentChange,
    hasData: true
  });

  return { percentChange, hasData: true };
};

/**
 * Get color based on map mode and school data
 */
export const getSchoolColor = (school, schoolsEnrollmentData, mode, marketShares = {}) => {
  if (mode === 'market-share') {
    return getMarketShareColor(marketShares[school.ncessch]?.['2022'] || 0);
  }
  
  const schoolData = schoolsEnrollmentData?.find(s => s.ncessch === school.ncessch);
  if (!schoolData) return '#808080';

  const { percentChange, hasData } = calculateEnrollmentChange(schoolData);
  if (!hasData) return '#808080';

  if (percentChange > 0) return '#008000';  // green for growth
  if (percentChange < 0) return '#FF0000';  // red for decline
  return '#808080';  // gray for no change
};

/**
 * Color logic for market share mode
 */
export const getMarketShareColor = (marketShare) => {
  if (marketShare > 50) return '#008000';
  if (marketShare > 25) return '#90EE90';
  if (marketShare > 10) return '#FFFF00';
  if (marketShare > 0) return '#FFA500';
  return '#FF0000';
};

export const driveTimeColors = {
  5: '#4338ca',
  10: '#4338ca',
  15: '#4338ca'
};

export const colorScales = {
  'market-share': [
    { color: '#008000', label: 'Market Share > 50%' },
    { color: '#90EE90', label: '25% < Market Share ≤ 50%' },
    { color: '#FFFF00', label: '10% < Market Share ≤ 25%' },
    { color: '#FFA500', label: '0% < Market Share ≤ 10%' },
    { color: '#FF0000', label: 'Market Share = 0%' }
  ],
  'geographic': [
    { color: '#008000', label: 'Enrollment Growth' },
    { color: '#FF0000', label: 'Enrollment Decline' },
    { color: '#808080', label: 'No Change/Data' }
  ]
};