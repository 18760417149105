import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { 
  ThemeProvider, 
  createTheme, 
  CssBaseline, 
  Box,
  CircularProgress,
} from '@mui/material';
import Navbar from './components/internal_layout/Navbar';
import Sidebar from './components/internal_layout/Sidebar';
import Footer from './components/Footer';
import Home from './components/Home';
import Map from './components/Map';
import Login from './components/external_site/Login';
import ResetPassword from './components/external_site/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import AddressDataVisualization from './components/AddressDataVisualization';
import DataEntryPage from './components/DataEntryPage';
import DriveTimePage from './components/DriveTimePage';
import GeographicExplorer from './components/geographic_explorer/GeographicExplorer';
import MarketShareAnalyzer from './components/marketshare_analyzer/MarketShareAnalyzer';
import DataReportsPage from './components/DataReportsPage';
import ProjectionsExplorer from './components/projections_explorer/ProjectionsExplorer';
import TermsOfService from './components/external_site/TermsOfService'; 
import HomePage from './components/external_site/HomePage';
import AboutUs from './components/external_site/AboutUs';
import { signOut } from 'firebase/auth';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { auth } from './firebase';
import useSchoolStore from './stores/schoolStore';

console.log('Current NODE_ENV:', process.env.NODE_ENV);

const theme = createTheme({
  palette: {
    primary: {
      main: '#4338ca',
      light: '#6366f1',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    grey: {
      200: '#f3f4f6', // Light grey for sidebar
    },
  },
});

const getUsernameFromEmail = (email) => {
  return email.split('@')[0];
};

function AppContent() {
  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  const location = useLocation();
  const { currentUser, loading: authLoading } = useAuth();
  
  // Initialize store when user logs in, but only after auth is ready
  useEffect(() => {
    const initializeStore = async () => {
      if (!authLoading && currentUser) {
        try {
          await useSchoolStore.getState().initialize();
        } catch (error) {
          console.error('Failed to initialize store:', error);
        }
      } else if (!authLoading && !currentUser) {
        useSchoolStore.getState().clearSchoolData();
      }
    };

    initializeStore();
  }, [currentUser, authLoading]);

  const handleLogout = () => {
    signOut(auth).catch((error) => {
      console.error('Logout error:', error);
    });
  };

  const handleSidebarToggle = (isOpen) => {
    setSidebarOpen(isOpen);
  };

  if (authLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const publicPages = ['/', '/about', '/login', '/reset-password', '/terms-of-service'];
  const isPublicPage = publicPages.includes(location.pathname);
  const drawerWidth = sidebarOpen ? 0 : 0;

  return (
    <Box sx={{ display: 'flex' }}>
      {!isPublicPage && <Sidebar onToggle={handleSidebarToggle} />}
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          width: { sm: `calc(100% - ${!isPublicPage ? drawerWidth : 0}px)` },
          marginLeft: { sm: isPublicPage ? 0 : `${drawerWidth}px` },
          display: 'flex', 
          flexDirection: 'column',
          minHeight: '100vh',
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        {!isPublicPage && <Navbar user={currentUser} onLogout={handleLogout} getUsernameFromEmail={getUsernameFromEmail} />}
        <Box sx={{ 
          flexGrow: 1, 
          p: isPublicPage ? 0 : 3, 
          mt: isPublicPage ? 0 : 8,
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/map" element={<ProtectedRoute user={currentUser}><Map /></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute user={currentUser}><Home user={currentUser} /></ProtectedRoute>} />
            <Route path="/address-visualization" element={<ProtectedRoute user={currentUser}><AddressDataVisualization /></ProtectedRoute>} />
            <Route path="/data-entry" element={<ProtectedRoute user={currentUser}><DataEntryPage /></ProtectedRoute>} />
            <Route path="/projections-explorer" element={<ProtectedRoute user={currentUser}><ProjectionsExplorer /></ProtectedRoute>} />
            <Route path="/geographic-explorer" element={<ProtectedRoute user={currentUser}><GeographicExplorer /></ProtectedRoute>} />
            <Route path="/marketshare-analyzer" element={<ProtectedRoute user={currentUser}><MarketShareAnalyzer /></ProtectedRoute>} />
            <Route path="/data-reports" element={<ProtectedRoute user={currentUser}><DataReportsPage /></ProtectedRoute>} />
            <Route path="/data-reports/drive-time" element={<ProtectedRoute user={currentUser}><DriveTimePage /></ProtectedRoute>} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Box>
        {!isPublicPage && <Footer />}
      </Box>
    </Box>
  );
}

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AppContent />
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;