import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { schoolsApi } from '../utils/apiService';
import { formatSchoolName } from '../utils/nameFormatter';

const useSchoolStore = create(
  devtools((set, get) => ({
    // Data states
    schools: [],
    selectedSchool: null,
    esriData: null,
    nearbySchools: {},
    enrollmentProjections: null,
    schoolsEnrollmentData: [],
    
    // Loading states
    schoolsLoading: false,
    contentLoading: false,
    
    // Error state
    error: null,

    // Action to fetch all schools
    fetchSchools: async () => {
      try {
        set({ schoolsLoading: true });
        const schools = await schoolsApi.getAllSchools();
        
        // Deduplicate schools by ncessch
        const uniqueSchools = Array.from(
          new Map(schools.map(school => [
            school.ncessch, 
            {...school, name: formatSchoolName(school.name)}
          ])).values()
        ).sort((a, b) => a.name.localeCompare(b.name));
    
        set({ schools: uniqueSchools, schoolsLoading: false });
      } catch (error) {
        console.error('Error fetching schools:', error);
        set({ error: error.message, schoolsLoading: false });
      }
    },

    // Action to set selected school and fetch its data
    selectSchool: async (school) => {
      set({ selectedSchool: school, contentLoading: true });
      
      if (school) {
        try {
          console.log('Starting data fetch for school:', {
            schoolId: school.ncessch,
            schoolName: school.name
          });
    
          const [marketShareData, nearbySchoolsResponse, projectionsData] = await Promise.all([
            schoolsApi.getMarketShareData(school.ncessch),
            schoolsApi.getAllNearbySchools(school.ncessch),
            schoolsApi.getEnrollmentProjections(school.ncessch)
          ]);
    
          // Log the nearby schools response to see what IDs we're getting
          console.log('Nearby schools response:', {
            current: Object.entries(nearbySchoolsResponse.current || {}).map(([time, schools]) => ({
              driveTime: time,
              schoolCount: schools.length,
              schoolIds: schools.map(s => s.ncessch)
            })),
            comparison: Object.entries(nearbySchoolsResponse.comparison || {}).map(([time, schools]) => ({
              driveTime: time,
              schoolCount: schools.length,
              schoolIds: schools.map(s => s.ncessch)
            }))
          });
    
          // Create Set of school IDs with more detailed logging
          const schoolIds = new Set([
            school.ncessch,
            ...Object.values(nearbySchoolsResponse.current || {})
              .flatMap(schools => schools.map(s => s.ncessch)),
            ...Object.values(nearbySchoolsResponse.comparison || {})
              .flatMap(schools => schools.map(s => s.ncessch))
          ]);
    
          console.log('School IDs prepared for enrollment data request:', {
            selectedSchoolId: school.ncessch,
            isSelectedSchoolInSet: schoolIds.has(school.ncessch),
            totalUniqueSchools: schoolIds.size,
            allSchoolIds: Array.from(schoolIds)
          });
    
          const enrollmentData = await schoolsApi.getSchoolsEnrollmentData(
            Array.from(schoolIds),
            school.ncessch
          );
    
          // Log detailed information about received data
          console.log('Enrollment data received:', {
            totalReceived: enrollmentData.length,
            selectedSchoolReceived: enrollmentData.some(d => d.ncessch === school.ncessch),
            selectedSchoolData: enrollmentData.find(d => d.ncessch === school.ncessch),
            firstFewSchools: enrollmentData.slice(0, 3).map(d => ({
              ncessch: d.ncessch,
              name: d.name,
              hasEnrollmentData: !!d.enrollment_by_grade,
              hasRaceData: !!d.race_data
            }))
          });
    
          set({
            esriData: marketShareData,
            nearbySchools: nearbySchoolsResponse,
            enrollmentProjections: projectionsData,
            schoolsEnrollmentData: enrollmentData,
            contentLoading: false,
            error: null
          });
    
        } catch (error) {
          console.error('Error fetching school data:', error);
          set({ 
            error: error.message, 
            contentLoading: false 
          });
        }
      }
    },

    // Action to clear school data
    clearSchoolData: () => {
      set({
        selectedSchool: null,
        esriData: null,
        nearbySchools: {},
        enrollmentProjections: null,
        schoolsEnrollmentData: [],
        error: null
      });
    },

    // Initialize store
    initialize: async () => {
      try {
        await get().fetchSchools();
      } catch (error) {
        console.error('Error initializing store:', error);
      }
    }
  }))
);

export default useSchoolStore;