// components/shared/AccordionSection.js
import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const AccordionSection = ({
  title,
  icon: Icon,
  expanded,
  onChange,
  children,
  summary,
  defaultExpanded = false,
  headerClassName,
  detailsClassName
}) => {
  return (
    <Accordion 
      expanded={expanded}
      onChange={onChange}
      defaultExpanded={defaultExpanded}
      sx={{ mt: 2 }}
    >
      <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`${title.toLowerCase()}-content`}
          id={`${title.toLowerCase()}-header`}
          className={headerClassName}
          sx={{
            '& .MuiAccordionSummary-content': {
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
              margin: summary ? '12px 0' : undefined,
            }
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
          }}>
            {Icon && <Icon sx={{ mr: 1, color: 'primary.main' }} />}
            <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
              {title}
            </Typography>
          </Box>
          {!expanded && summary && (
            <Typography 
              variant="body2" 
              sx={{ 
                color: 'text.secondary',
                fontSize: '0.875rem'
              }}
            >
              {summary}
            </Typography>
          )}
        </AccordionSummary>
      <AccordionDetails className={detailsClassName}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionSection;