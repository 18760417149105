import React, { useState, useEffect, Suspense, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Grid, 
  Skeleton,
  Button,
  Typography
} from '@mui/material';
import {
  Map as MapIcon,
  TrendingUp,
  Assessment,
  MapsHomeWork,
  AutoGraphOutlined,
  DescriptionOutlined,
  Explore,
} from '@mui/icons-material';

// Shared Components
import PageLayout from '../shared_components/pageLayout';
import LoadingErrorWrapper from '../shared_components/loadingErrorWrapper';
import AccordionSection from '../shared_components/accordionSection';
import AccordionContainer from '../shared_components/accordionContainer';
import StickyFilters from '../shared_components/stickyFilters';
import InitialSchoolSelector from '../shared_components/initialSchoolSelector';
import UnifiedSchoolMap from '../shared_components/unifiedSchoolMap';

// Custom Hook
import { useSchoolData } from '../../hooks/useSchoolData';
import { useAccordionState } from '../../hooks/useAccordionState';

// Utils
import { filterSchools, getAvailableSchoolTypes } from '../../utils/schoolUtils';
import { formatSchoolName } from '../../utils/nameFormatter';

// Components
import MarketSharePopup from './MarketSharePopup';

// Lazy load components
const AttendMarketShareCard = React.lazy(() => import('./AttendMarketShareCard'));
const ResideMarketShareCard = React.lazy(() => import('./ResideMarketShareCard'));
const EnrollmentComparisonChart = React.lazy(() => import('./EnrollmentComparisonChart'));

function MarketShareAnalyzer() {
  const navigate = useNavigate();
  
  // State declarations
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [governanceFilter, setGovernanceFilter] = useState('All');
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedDriveTime, setSelectedDriveTime] = useState(10);
  const [schoolTypeFilter, setSchoolTypeFilter] = useState('All');
  const [availableSchoolTypes, setAvailableSchoolTypes] = useState(['All']);

  // Use accordion state hook
  const {
    expandedSections,
    handleExpandedChange,
    handleExpandAll,
    handleCollapseAll,
  } = useAccordionState({
    marketShare: false,
    map: false,
    enrollment: false,
    actions: false
  });

  // Use the school data hook
  const {
    schools,
    esriData,
    nearbySchools,
    schoolsEnrollmentData,
    error,
    isLoading,
    schoolsLoading
  } = useSchoolData(selectedSchool);

  // Update available school types
  useEffect(() => {
    if (schoolsEnrollmentData.length > 0) {
      const types = getAvailableSchoolTypes(schoolsEnrollmentData);
      setAvailableSchoolTypes(types);
    }
  }, [schoolsEnrollmentData]);

  // Filter schools based on criteria
    const filteredSchools = useMemo(() => {
      if (!selectedSchool || !nearbySchools) return [];
      
      // Get schools for the selected drive time
      const schoolsForSelectedDriveTime = nearbySchools[selectedDriveTime] || [];
      
      // Create array with selected school and nearby schools
      const allSchools = [
        { ...selectedSchool, drive_time: 0 }, 
        ...schoolsForSelectedDriveTime.map(school => ({
          ...school,
          drive_time: selectedDriveTime
        }))
      ];
    
      // Only filter if we have schools and enrollment data
      if (allSchools.length === 0 || !schoolsEnrollmentData) return [];
    
      // Memoize the filtered results
      return filterSchools(
        allSchools,
        {
          selectedGrades,
          governanceFilter,
          schoolTypeFilter
        },
        schoolsEnrollmentData
      );
    }, [
      selectedSchool, // Include the whole object since we use its properties
      nearbySchools,
      selectedDriveTime,
      selectedGrades,
      governanceFilter,
      schoolTypeFilter,
      schoolsEnrollmentData // Include the whole array since we use it in filtering
    ]);

    const handleSchoolChange = (event) => {
      const schoolId = event.target.value;
      const school = schools.find(s => s.ncessch === schoolId);
      if (school) {
        setSelectedSchool({...school, name: formatSchoolName(school.name)});
      }
    };

  const handleGradeChange = (event) => {
    const value = event.target.value;
    setSelectedGrades(typeof value === 'string' ? value.split(',') : value);
  };

  const handleGovernanceChange = (event) => {
    setGovernanceFilter(event.target.value);
  };

  const handleDriveTimeChange = (event) => {
    setSelectedDriveTime(Number(event.target.value));
  };

  const handleSchoolTypeChange = (event) => {
    setSchoolTypeFilter(event.target.value);
  };

  const contentLoading = isLoading && selectedSchool;

  return (
    <PageLayout
      title="Competitive Explorer"
      description={selectedSchool
        ? "Analyze market share data for the selected school. Use the filters to refine your analysis."
        : "Use this tool to analyze market share by students who reside and attend schools in your area. Start by selecting a school."
      }
    >
      <LoadingErrorWrapper
        loading={schoolsLoading}
        error={error}
        initialLoading={true}
      >
        {!selectedSchool ? (
          <InitialSchoolSelector
            schools={schools}
            selectedSchool={selectedSchool}
            onSchoolChange={handleSchoolChange}
          />
        ) : (
          <LoadingErrorWrapper
            loading={contentLoading}
            error={error}
            initialLoading={false}
          >
            <StickyFilters
              schools={schools}
              selectedSchool={selectedSchool}
              governanceFilter={governanceFilter}
              selectedGrades={selectedGrades}
              selectedDriveTime={selectedDriveTime}
              schoolTypeFilter={schoolTypeFilter}
              availableSchoolTypes={availableSchoolTypes}
              onSchoolChange={handleSchoolChange}
              onGovernanceChange={handleGovernanceChange}
              onGradeChange={handleGradeChange}
              onDriveTimeChange={handleDriveTimeChange}
              onSchoolTypeChange={handleSchoolTypeChange}
              expandedSections={expandedSections}
              onExpandAll={handleExpandAll}
              onCollapseAll={handleCollapseAll}
              showSchoolType={true}
            />

            <AccordionContainer 
              expandedSections={expandedSections}
              onSectionChange={handleExpandedChange}
            >
              <AccordionSection
                id="marketShare"
                title="Headlines"
                icon={Assessment}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                      <AttendMarketShareCard 
                        selectedSchool={selectedSchool}
                        filteredSchools={filteredSchools}
                        selectedDriveTime={selectedDriveTime}
                        schoolsEnrollmentData={schoolsEnrollmentData}
                        governanceFilter={governanceFilter}
                        selectedGrades={selectedGrades}
                        schoolTypeFilter={schoolTypeFilter}
                      />
                    </Suspense>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                      <ResideMarketShareCard
                        selectedSchool={selectedSchool}
                        filteredSchools={filteredSchools}
                        marketShareData={esriData}
                        selectedDriveTime={selectedDriveTime}
                        selectedGrades={selectedGrades}
                        schoolsEnrollmentData={schoolsEnrollmentData}
                        governanceFilter={governanceFilter}
                      />
                    </Suspense>
                  </Grid>
                </Grid>
              </AccordionSection>

              <AccordionSection
                id="map"
                title="Community Map"
                icon={MapIcon}
              >
                <Suspense fallback={<Skeleton variant="rectangular" height={500} />}>
                <UnifiedSchoolMap 
                    mode="market-share"
                    selectedSchool={selectedSchool}
                    filteredSchools={filteredSchools}
                    esriData={esriData}
                    visibleDriveTimes={[selectedDriveTime]}
                    schoolsEnrollmentData={schoolsEnrollmentData}
                    governanceFilter={governanceFilter}
                    selectedGrades={selectedGrades}
                    schoolTypeFilter={schoolTypeFilter}
                    PopupComponent={MarketSharePopup}
                  />
                </Suspense>
              </AccordionSection>

              <AccordionSection
                id="enrollment"
                title="Nearby Schools Comparison"
                icon={TrendingUp}
              >
                <Suspense fallback={<Skeleton variant="rectangular" height={500} />}>
                  <EnrollmentComparisonChart
                    filteredSchools={filteredSchools}
                    schoolsEnrollmentData={schoolsEnrollmentData}
                    selectedGrades={selectedGrades}
                    governanceFilter={governanceFilter}
                    schoolTypeFilter={schoolTypeFilter}
                  />
                </Suspense>
              </AccordionSection>

              <AccordionSection
                id="actions"
                title="Now What?"
                icon={Explore}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => navigate('/geographic-explorer')}
                      sx={{
                        p: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        textTransform: 'none'
                      }}
                    >
                      <MapsHomeWork sx={{ fontSize: 40, mb: 2, color: 'primary.main' }} />
                      <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                        Geographic Explorer
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Explore demographic and enrollment trends in your community
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => navigate('/school-dashboard')}
                      sx={{
                        p: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        textTransform: 'none'
                      }}
                    >
                      <AutoGraphOutlined sx={{ fontSize: 40, mb: 2, color: 'primary.main' }} />
                      <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                        Projected Enrollment
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Explore historical and projected enrollment data for the next five years
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => navigate('/data-reports')}
                      sx={{
                        p: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        textTransform: 'none'
                      }}
                    >
                      <DescriptionOutlined sx={{ fontSize: 40, mb: 2, color: 'primary.main' }} />
                      <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                        Data Reports
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Download detailed reports, including Esri demographic data for selected schools
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </AccordionSection>
            </AccordionContainer>
          </LoadingErrorWrapper>
        )}
      </LoadingErrorWrapper>
    </PageLayout>
  );
}

export default MarketShareAnalyzer;