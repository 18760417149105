// components/shared_components/stickyFilters.js
import React from 'react';
import { 
  Box,
  Container,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  UnfoldLess,
  UnfoldMore,
} from '@mui/icons-material';
import SharedFilters from './sharedFilters';

const StickyFilters = ({
  // Filter props
  schools,
  selectedSchool,
  governanceFilter,
  selectedGrades,
  selectedDriveTime,
  schoolTypeFilter,
  availableSchoolTypes,
  onSchoolChange,
  onGovernanceChange,
  onGradeChange,
  onDriveTimeChange,
  onSchoolTypeChange,
  onHasPreKChange,
  // Expansion props
  expandedSections,
  onExpandAll,
  onCollapseAll,
  showSchoolType = true,
}) => {
  const isAllExpanded = Object.values(expandedSections).every(value => value === true);

  return (
    <Box
      sx={{
        position: 'sticky',
        top: { xs: 56, sm: 64 },
        backgroundColor: 'background.default',
        backdropFilter: 'blur(8px)',
        zIndex: 1200,
        borderBottom: '1px solid',
        borderColor: 'divider',
        pt: 2,
        pb: 2,
        mb: 3,
        // Align with container edges
        width: '100%',
      }}
    >
      <Container 
        maxWidth="xl"
        sx={{
          // Match padding with PageLayout container
          px: { xs: 2, sm: 3 },
        }}
      >
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Filters
          </Typography>
          <Tooltip title={isAllExpanded ? "Collapse all sections" : "Expand all sections"}>
            <IconButton 
              onClick={isAllExpanded ? onCollapseAll : onExpandAll}
              size="small"
              sx={{ 
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.light',
                  color: 'primary.dark',
                }
              }}
            >
              {isAllExpanded ? <UnfoldLess /> : <UnfoldMore />}
            </IconButton>
          </Tooltip>
        </Box>
        
        <SharedFilters
          schools={schools}
          selectedSchool={selectedSchool}
          governanceFilter={governanceFilter}
          selectedGrades={selectedGrades}
          selectedDriveTime={selectedDriveTime}
          schoolTypeFilter={schoolTypeFilter}
          availableSchoolTypes={availableSchoolTypes}
          onSchoolChange={onSchoolChange}
          onGovernanceChange={onGovernanceChange}
          onGradeChange={onGradeChange}
          onDriveTimeChange={onDriveTimeChange}
          onSchoolTypeChange={onSchoolTypeChange}
          onHasPreKChange={onHasPreKChange}
          showSchoolType={showSchoolType}
          compact={true}
          sticky={true}
        />
      </Container>
    </Box>
  );
};

export default StickyFilters;