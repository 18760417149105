import React from 'react';
import { 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
} from '@mui/material';
import { formatSchoolName } from '../../utils/nameFormatter';

const InitialSchoolSelector = ({ schools, selectedSchool, onSchoolChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="school-select-label">Select a School</InputLabel>
      <Select
        labelId="school-select-label"
        id="school-select"
        value={selectedSchool?.ncessch || ''}
        label="Select a School"
        onChange={onSchoolChange}
      >
        {schools.map((school) => (
          <MenuItem key={school.ncessch} value={school.ncessch}>
            {formatSchoolName(school.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default InitialSchoolSelector;