import { useState, useCallback } from 'react';

export const useAccordionState = (initialSections = {}) => {
  const [expandedSections, setExpandedSections] = useState(initialSections);

  const handleExpandedChange = useCallback((sectionId) => (event, isExpanded) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionId]: isExpanded
    }));
  }, []);

  const handleExpandAll = useCallback(() => {
    setExpandedSections(
      Object.fromEntries(
        Object.keys(expandedSections).map(key => [key, true])
      )
    );
  }, [expandedSections]);

  const handleCollapseAll = useCallback(() => {
    setExpandedSections(
      Object.fromEntries(
        Object.keys(expandedSections).map(key => [key, false])
      )
    );
  }, [expandedSections]);

  const isAllExpanded = Object.values(expandedSections).every(value => value === true);

  return {
    expandedSections,
    handleExpandedChange,
    handleExpandAll,
    handleCollapseAll,
    isAllExpanded
  };
};