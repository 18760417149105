import React from 'react';
import { Typography, Box, Divider, Paper } from '@mui/material';
import { formatSchoolName } from '../../utils/nameFormatter';
import { TrendingUp, TrendingDown, TrendingFlat } from '@mui/icons-material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const MarketSharePopup = ({ school, marketShareData, enrollmentData }) => {
  const currentMarketShare = marketShareData['2022'] || 0;
  const pastMarketShare = marketShareData['2017'] || 0;
  const changeInMarketShare = currentMarketShare - pastMarketShare;

  const currentEnrollment = enrollmentData['2022'] || 0;
  const pastEnrollment = enrollmentData['2017'] || 0;

  const getTrendIcon = (change) => {
    if (change === 0) return <TrendingFlat color="action" />;
    return change > 0 ? <TrendingUp color="success" /> : <TrendingDown color="error" />;
  };

  const getBoxColor = (change) => {
    if (change > 0) return '#e8f5e9'; // light green
    if (change < 0) return '#ffebee'; // light red
    return '#f5f5f5'; // light grey
  };

  const formatPercent = (value) => `${(value * 100).toFixed(0)}%`;
  const formatEnrollment = (value) => value.toLocaleString();

  const chartData = {
    labels: ['2017', '2022'],
    datasets: [
      {
        data: [pastMarketShare, currentMarketShare],
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(153, 102, 255, 0.6)'],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        beginAtZero: true,
        max: 1,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            const year = context.label;
            const marketShare = formatPercent(context.raw);
            const enrollment = year === '2017' ? pastEnrollment : currentEnrollment;
            return `Market Share: ${marketShare} (${formatEnrollment(enrollment)} students)`;
          }
        }
      },
      legend: {
        display: false
      },
      // This will prevent any default labels from being rendered
      datalabels: {
        display: false
      }
    }
  };

  const plugins = [{
    afterDraw: (chart) => {
      const ctx = chart.ctx;
      chart.data.datasets[0].data.forEach((value, index) => {
        const meta = chart.getDatasetMeta(0);
        const x = meta.data[index].x;
        const y = meta.data[index].y;
        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        ctx.font = 'bold 14px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(formatPercent(value), x, y - 5);
        ctx.restore();
      });
    }
  }];

  return (
    <Box sx={{ width: 300 }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0.5 }}>
        {formatSchoolName(school.name)}
      </Typography>
      <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
        {school.charter === 'Yes' ? 'Charter' : 'District'} School | {school.grade_range}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Paper 
        elevation={3} 
        sx={{ 
          p: 2, 
          textAlign: 'center', 
          mb: 2, 
          backgroundColor: getBoxColor(changeInMarketShare),
          transition: 'background-color 0.3s'
        }}
      >
        <Typography variant="h6" sx={{ mb: 1 }}>2022 Market Share</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {getTrendIcon(changeInMarketShare)}
          <Typography variant="h5" sx={{ ml: 0.5 }}>{formatPercent(currentMarketShare)}</Typography>
        </Box>
      </Paper>
      <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold' }}>
        Market Share Trend
      </Typography>
      <Box sx={{ height: 180, width: '100%' }}>
        <Bar data={chartData} options={chartOptions} plugins={plugins} />
      </Box>
    </Box>
  );
};

export default MarketSharePopup;