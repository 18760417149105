import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { MapContainer, TileLayer, Marker, GeoJSON, useMap, ZoomControl } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import PropTypes from 'prop-types';
import { Typography, Box, Divider, IconButton, Collapse, Paper } from '@mui/material';
import { 
  Close as CloseIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
  LocationOn as LocationOnIcon,
  SchoolOutlined as SchoolOutlinedIcon,
  School as SchoolIcon 
} from '@mui/icons-material';

// Import existing utilities
import { 
  customIcon, 
  getSchoolColor,
  driveTimeColors,
  colorScales,
} from '../../utils/unifiedMapUtils';

import {
  calculateMarketShares,
  filterSchools,
  deduplicateSchools,
} from '../../utils/schoolUtils';

const Legend = ({ selectedSchool, mode }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 20,
        right: 10,
        backgroundColor: 'white',
        borderRadius: '5px',
        zIndex: 1000,
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        maxWidth: '200px'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
        <Typography variant="subtitle2">Legend</Typography>
        <IconButton size="small" onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <Box sx={{ p: 1 }}>
          {selectedSchool && (
            <Box display="flex" alignItems="center" mb={1}>
              {selectedSchool.charter === 'Yes' ? (
                <SchoolIcon sx={{ mr: 1, color: '#4338ca' }} />
              ) : (
                <LocationOnIcon sx={{ mr: 1, color: '#4338ca' }} />
              )}
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {selectedSchool.name}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center" mb={1}>
            <SchoolOutlinedIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography variant="body2">Charter School</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            <LocationOnOutlinedIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography variant="body2">District School</Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          {colorScales[mode].map(({ color, label }) => (
            <Box key={label} display="flex" alignItems="center" mb={1}>
              <Box width={20} height={20} bgcolor={color} mr={1} />
              <Typography variant="body2">{label}</Typography>
            </Box>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

const MapOverlay = ({ position, children }) => {
  const map = useMap();
  const [point, setPoint] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!map || !position) return;

    const updatePosition = () => {
      // Convert LatLng to pixel coordinates
      const latLng = L.latLng(position[0], position[1]);
      const point = map.latLngToContainerPoint(latLng);
      setPoint(point);
    };

    updatePosition();

    map.on('zoom', updatePosition);
    map.on('move', updatePosition);
    map.on('moveend', updatePosition);

    return () => {
      map.off('zoom', updatePosition);
      map.off('move', updatePosition);
      map.off('moveend', updatePosition);
    };
  }, [map, position]);

  if (!position) return null;

  return (
    <div style={{
      position: 'absolute',
      left: point.x,
      top: point.y,
      transform: 'translate(-50%, -50%)',
      pointerEvents: 'none',
      zIndex: 900
    }}>
      {children}
    </div>
  );
};

const InfoPanel = ({ school, data, onClose, PopupComponent }) => {
  console.log('InfoPanel component called with:', {
    schoolName: school?.name,
    hasData: !!data,
    hasPopupComponent: !!PopupComponent
  });

  if (!school) {
    console.log('InfoPanel early return - no school');
    return null;
  }

  return (
    <Paper
      sx={{
        position: 'absolute',
        left: 10,
        top: 10,
        zIndex: 1100,
        padding: 2,
        width: {
          xs: '90%',
          sm: 330,
          md: 330,
        },
        maxWidth: 500,
        maxHeight: 'calc(100% - 20px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: 'white',
        boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{ 
          position: 'absolute', 
          right: 8, 
          top: 8,
          zIndex: 1200
        }}
      >
        <CloseIcon />
      </IconButton>
      <PopupComponent 
        school={school}
        {...data}
      />
    </Paper>
  );
};

const SelectionIndicator = () => (
  <div className="map-selection-indicator">
    <svg width="40" height="40" viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="18" fill="none" stroke="#3388ff" strokeWidth="2" />
      <circle cx="20" cy="20" r="4" fill="#3388ff" />
    </svg>
  </div>
);

const MapContent = ({
  mode,
  selectedSchool,
  filteredSchools,
  esriData,
  visibleDriveTimes,
  schoolsEnrollmentData,
  governanceFilter,
  selectedGrades,
  schoolTypeFilter,
  PopupComponent,
  focusedSchool,
  onSchoolSelect,
  initialZoom = 12,
  mapReady,
  mapRef,
  setMapReady
}) => {
  const map = useMap();
  const [geoJsonData, setGeoJsonData] = useState({});
  const [selectedMarkerSchool, setSelectedMarkerSchool] = useState(null);
  const [selectionIndicatorPos, setSelectionIndicatorPos] = useState(null);
  const [nearbySchoolCount, setNearbySchoolCount] = useState(0);
  const mountedRef = useRef(true);

  // Calculate market shares if in market-share mode
  const marketShares = useMemo(() => {
    if (mode !== 'market-share') return {};
    return calculateMarketShares(filteredSchools, schoolsEnrollmentData, selectedGrades);
  }, [mode, filteredSchools, schoolsEnrollmentData, selectedGrades]);

  // Set mounted ref on mount
  useEffect(() => {
    console.log('Component mounted');
    mountedRef.current = true;
    return () => {
      console.log('Component unmounting');
      mountedRef.current = false;
    };
  }, []); // Empty dependency array to only run on mount/unmount

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // Map initialization with cleanup
  useEffect(() => {
    if (!map) return;
    
    let isSubscribed = true;
    
    const handleMapReady = () => {
      if (!isSubscribed || !mountedRef.current) return;
      
      map.scrollWheelZoom.disable();
      
      const handleScroll = (e) => {
        if (!mountedRef.current) return;
        if (e.ctrlKey) {
          map.scrollWheelZoom.enable();
        } else {
          map.scrollWheelZoom.disable();
        }
      };
  
      map.on('preclick', () => {
        if (mountedRef.current) map.scrollWheelZoom.enable();
      });
      
      map.on('mouseout', () => {
        if (mountedRef.current) map.scrollWheelZoom.disable();
      });
  
      document.addEventListener('keydown', handleScroll);
      document.addEventListener('keyup', handleScroll);
  
      return () => {
        document.removeEventListener('keydown', handleScroll);
        document.removeEventListener('keyup', handleScroll);
        map.off('preclick');
        map.off('mouseout');
      };
    };
  
    if (map._loaded) {
      handleMapReady();
    } else {
      map.once('load', handleMapReady);
    }

    return () => {
      isSubscribed = false;
      if (map) {
        map.off('load');
      }
    };
  }, [map]);

  // Process GeoJSON data
  const updateGeoJsonData = useCallback((data) => {
    if (!data?.drive_times || !mapReady || !mapRef?.current || !mountedRef.current) return;
    
    const newGeoJsonData = {};
    
    Object.entries(data.drive_times).forEach(([driveTime, timeData]) => {
      if (!timeData?.drive_time_polygon) return;
      
      try {
        let polygonData = timeData.drive_time_polygon;
        if (typeof polygonData === 'string') {
          polygonData = JSON.parse(polygonData);
        }
        
        const coordinates = polygonData.rings || polygonData;
        
        if (coordinates?.length > 0) {
          newGeoJsonData[parseInt(driveTime)] = {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: coordinates
            }
          };
        }
      } catch (error) {
        console.error(`Error processing drive time ${driveTime}:`, error);
      }
    });
    
    if (!mountedRef.current) return;
    setGeoJsonData(newGeoJsonData);
    
    // Update map bounds
    if (Object.keys(newGeoJsonData).length > 0 && mapRef.current && mapReady) {
      const visiblePolygons = visibleDriveTimes
        .map(time => newGeoJsonData[time])
        .filter(Boolean);

      if (visiblePolygons.length > 0 && mountedRef.current) {
        try {
          const geoJsonLayer = L.geoJSON({ 
            type: 'FeatureCollection', 
            features: visiblePolygons 
          });
          
          requestAnimationFrame(() => {
            if (mapRef.current && mapReady && mountedRef.current) {
              mapRef.current.fitBounds(geoJsonLayer.getBounds(), { 
                padding: [50, 50], 
                maxZoom: 14,
                animate: false
              });
            }
          });
        } catch (error) {
          console.error('Error fitting bounds:', error);
          if (mapRef.current && mapReady && mountedRef.current) {
            mapRef.current.setView([selectedSchool.latitude, selectedSchool.longitude], 13);
          }
        }
      }
    }
  }, [mapReady, mapRef, visibleDriveTimes, selectedSchool]);

  useEffect(() => {
    if (esriData) {
      updateGeoJsonData(esriData);
    }
  }, [esriData, updateGeoJsonData]);

  // Handle marker clicks
  const handleMarkerClick = useCallback((school) => {
    console.log('Starting handleMarkerClick for:', school.name);
  
    if (!map) {
      console.log('No map reference');
      return;
    }
  
    if (selectedMarkerSchool?.ncessch === school.ncessch) {
      console.log('Closing popup for:', school.name);
      setSelectedMarkerSchool(null);
      setSelectionIndicatorPos(null);
      map.setView([selectedSchool.latitude, selectedSchool.longitude], map.getZoom());
    } else {
      console.log('Opening popup for:', school.name);
      setSelectedMarkerSchool(school);
      
      // Use the school's coordinates directly
      setSelectionIndicatorPos([school.latitude, school.longitude]);
      map.setView([school.latitude, school.longitude], map.getZoom());
    }
  
    onSchoolSelect?.(school.ncessch);
  }, [map, selectedMarkerSchool, selectedSchool, onSchoolSelect]);
  

  const handleInfoPanelClose = useCallback(() => {
    if (!mountedRef.current) return;
    
    setSelectedMarkerSchool(null);
    setSelectionIndicatorPos(null);
    if (map && selectedSchool && mountedRef.current) {
      map.setView([selectedSchool.latitude, selectedSchool.longitude], map.getZoom());
    }
    onSchoolSelect?.(null);
  }, [map, selectedSchool, onSchoolSelect]);

  // Calculate visible schools
  const visibleSchools = useMemo(() => {
    const filters = {
      selectedGrades,
      governanceFilter,
      schoolTypeFilter,
    };
    
    const filtered = filterSchools(filteredSchools, filters, schoolsEnrollmentData);
    const uniqueSchools = deduplicateSchools(filtered);
    
    if (!uniqueSchools.find(school => school.ncessch === selectedSchool.ncessch)) {
      uniqueSchools.unshift(selectedSchool);
    }
    
    const nearbyCount = uniqueSchools.filter(school => 
      school.ncessch !== selectedSchool.ncessch && 
      visibleDriveTimes.includes(school.drive_time)
    ).length;
    setNearbySchoolCount(nearbyCount);

    return uniqueSchools;
  }, [
    filteredSchools, 
    selectedGrades, 
    governanceFilter, 
    schoolTypeFilter, 
    schoolsEnrollmentData, 
    selectedSchool,
    visibleDriveTimes
  ]);

  // Memoized markers
  const memoizedMarkers = useMemo(() => 
    visibleSchools
      .filter(school => 
        school?.latitude && 
        school?.longitude &&
        (visibleDriveTimes.includes(school.drive_time) || school.ncessch === selectedSchool.ncessch)
      )
      .map(school => {
        const color = getSchoolColor(school, schoolsEnrollmentData, mode, marketShares);
        const isSelected = school.ncessch === selectedSchool.ncessch;
        const isFocused = school.ncessch === focusedSchool?.ncessch;
        let finalColor = color;
        let opacity = 1;

        if (isSelected) {
          finalColor = '#4338ca';
        } else if (isFocused) {
          finalColor = '#2196f3';
        } else if (focusedSchool) {
          opacity = 0.3;
        }

        return (
          <Marker 
            key={school.ncessch}
            position={[school.latitude, school.longitude]} 
            icon={customIcon({ 
              color: finalColor, 
              isSelected: isSelected || isFocused,
              isCharter: school.charter === 'Yes',
              opacity 
            })}
            eventHandlers={{
              click: (e) => {
                e.originalEvent.stopPropagation();
                handleMarkerClick(school);
              }
            }}
          />
        );
      }),
    [
      visibleSchools,
      visibleDriveTimes,
      selectedSchool,
      focusedSchool,
      schoolsEnrollmentData,
      mode,
      marketShares,
      handleMarkerClick
    ]
  );

  // Memoized GeoJSON layers
  const memoizedGeoJSON = useMemo(() => 
    Object.entries(geoJsonData)
      .map(([driveTime, data]) => 
        visibleDriveTimes.includes(parseInt(driveTime)) && data && (
          <GeoJSON 
            key={driveTime}
            data={data} 
            style={() => ({
              color: driveTimeColors[driveTime],
              weight: 2,
              fillColor: driveTimeColors[driveTime],
              fillOpacity: 0.1,
              zIndex: 400
            })}
          />
        )
      )
      .filter(Boolean),
    [geoJsonData, visibleDriveTimes]
  );

  return (
    <>
      <TileLayer
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>'
      />
      {memoizedMarkers}
      {memoizedGeoJSON}
      
      {/* School count */}
      {!selectedMarkerSchool && (
        <div 
          style={{
            position: 'absolute',
            top: '20px',
            left: '20px',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            padding: '8px 12px',
            borderRadius: '4px',
            zIndex: 1000,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}
        >
          <Typography variant="body2">
            Total Nearby Schools: {nearbySchoolCount}
          </Typography>
        </div>
      )}

      {/* Legend */}
      <Legend 
        selectedSchool={selectedSchool}
        mode={mode}
      />

      {/* Info Panel */}
      {selectedMarkerSchool && (
        <InfoPanel 
          school={selectedMarkerSchool}
          data={{
            schoolData: schoolsEnrollmentData?.find(s => s.ncessch === selectedMarkerSchool.ncessch)
          }}
          onClose={handleInfoPanelClose}
          PopupComponent={PopupComponent}
        />
      )}

      {/* Selection Indicator */}
      {selectionIndicatorPos && (
        <MapOverlay position={selectionIndicatorPos}>
          <SelectionIndicator />
        </MapOverlay>
      )}
    </>
  );
};

const UnifiedSchoolMap = ({ 
  mode = 'geographic',
  selectedSchool, 
  filteredSchools, 
  esriData, 
  visibleDriveTimes = [], 
  schoolsEnrollmentData, 
  governanceFilter,
  selectedGrades = [],
  schoolTypeFilter,
  PopupComponent,
  focusedSchool,
  onSchoolSelect,
  initialZoom = 12
}) => {
  const [mapReady, setMapReady] = useState(false);
  const mapRef = useRef(null);

  const handleMapReady = useCallback((e) => {
    mapRef.current = e.target;
    setMapReady(true);
    // Trigger a resize event after the map is mounted to ensure proper sizing
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }, []);

  const mapKey = useMemo(
    () => `${selectedSchool?.ncessch}-${visibleDriveTimes.join('-')}-${Date.now()}`, 
    [selectedSchool?.ncessch, visibleDriveTimes]
  );

  if (!selectedSchool) {
    return <Typography>Please select a school to view the map.</Typography>;
  }

  return (
    <div 
      className="relative w-full h-full" 
      style={{ 
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <MapContainer 
        key={mapKey}
        center={[selectedSchool.latitude, selectedSchool.longitude]}
        zoom={initialZoom}
        style={{ 
          position: 'absolute',
          height: '100%', 
          width: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
        zoomControl={false}
        whenReady={handleMapReady}
      >
        <ZoomControl position="topright" />
        <MapContent 
          mode={mode}
          selectedSchool={selectedSchool}
          filteredSchools={filteredSchools}
          esriData={esriData}
          visibleDriveTimes={visibleDriveTimes}
          schoolsEnrollmentData={schoolsEnrollmentData}
          governanceFilter={governanceFilter}
          selectedGrades={selectedGrades}
          schoolTypeFilter={schoolTypeFilter}
          PopupComponent={PopupComponent}
          focusedSchool={focusedSchool}
          onSchoolSelect={onSchoolSelect}
          initialZoom={initialZoom}
          mapReady={mapReady}
          mapRef={mapRef}
          setMapReady={setMapReady}
        />
      </MapContainer>

      <div 
        style={{
          position: 'absolute',
          bottom: '20px',
          left: '20px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: '4px 12px',
          borderRadius: '4px',
          zIndex: 1000,
          maxWidth: '200px',
          pointerEvents: 'none'
        }}
      >
        <Typography variant="caption">
          Hold Ctrl to zoom with scroll wheel, or click to enable zoom
        </Typography>
      </div>
    </div>
  );
};

UnifiedSchoolMap.propTypes = {
  mode: PropTypes.oneOf(['geographic', 'market-share']),
  selectedSchool: PropTypes.shape({
    ncessch: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    charter: PropTypes.string.isRequired,
  }).isRequired,
  filteredSchools: PropTypes.arrayOf(PropTypes.object).isRequired,
  esriData: PropTypes.object,
  visibleDriveTimes: PropTypes.arrayOf(PropTypes.number),
  schoolsEnrollmentData: PropTypes.arrayOf(PropTypes.object).isRequired,
  governanceFilter: PropTypes.string,
  selectedGrades: PropTypes.arrayOf(PropTypes.string),
  schoolTypeFilter: PropTypes.string,
  PopupComponent: PropTypes.elementType.isRequired,
  focusedSchool: PropTypes.object,
  onSchoolSelect: PropTypes.func.isRequired,
  initialZoom: PropTypes.number
};

UnifiedSchoolMap.defaultProps = {
  mode: 'geographic',
  visibleDriveTimes: [],
  selectedGrades: [],
  governanceFilter: 'All',
  schoolTypeFilter: 'All',
  esriData: null,
  focusedSchool: null,
  initialZoom: 12
};

export default UnifiedSchoolMap;